import { BrowserRouter as Router } from "react-router-dom"
import { NavHashLink, HashLink } from "react-router-hash-link"
import { useState } from "react"
import { Container } from "./styles"
import { ReactComponent as Logo } from "../../assets/logo.svg"

export function Header() {
  const [isActive, setActive] = useState(false)

  const toggleTheme = () => {
    let html = document.getElementsByTagName("html")[0]
    html.classList.toggle("light")
  }

  const closeMenu = () => {
    setActive(false)
  }

  return (
    <Container className='header-fixed'>
      <Router>
        <HashLink smooth to='#home' className='logo'>
          <Logo />
        </HashLink>

        <nav className={isActive ? "active" : ""}>
          <NavHashLink smooth to='#home' onClick={closeMenu}>
            Home
          </NavHashLink>
          <NavHashLink smooth to='#sobre' onClick={closeMenu}>
            Scopri di piu'
          </NavHashLink>
          <NavHashLink smooth to='#portfolio' onClick={closeMenu}>
            Storie
          </NavHashLink>
          <NavHashLink smooth to='#contato' onClick={closeMenu}>
            Contatti
          </NavHashLink>
        </nav>
      </Router>
    </Container>
  )
}
