import "./App.css"
import { GlobalStyle } from "./styles/global"
import { Header } from "./components/Header/Header"

function App() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <Header></Header>
    </>
  )
}

export default App
